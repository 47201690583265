import {
  ErrorBoundary as Boundary,
  type ErrorBoundaryProps,
} from "react-error-boundary";
import { catchError } from "~/utils/catchError";

type Props = Pick<ErrorBoundaryProps, "children" | "fallback"> & {
  context?: string;
};

export function ErrorBoundary({ children, context, fallback = null }: Props) {
  return (
    <Boundary
      fallback={fallback}
      onError={(error, { componentStack }) => {
        catchError(error, { componentStack, context });
      }}
    >
      {children}
    </Boundary>
  );
}
